import { faPenNib, faSquareFull, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import * as drawMode from '../../store/actions/drawMode';

const Toolbar = (props) => {
    return (
        <div className="toolbar" style={props.showToolbar ? {display: 'flex'} : {display: 'none'}}>
            <div className={"toolbar__item " + (props.activeElement === 'polyline' ? 'toolbar__active' : '') } onClick={() => props.drawMode(drawMode.POLYGON_MODE)}>
                <FontAwesomeIcon className="toolbar__icon" icon={faPenNib} size="2x" />
            </div>
            <div className={"toolbar__item " + (props.activeElement === 'rect' ? 'toolbar__active' : '') } onClick={() => props.drawMode(drawMode.SQUARE_MODE)}>
                <FontAwesomeIcon className="toolbar__icon" icon={faSquareFull} size="2x" />
            </div>
            <div className={"toolbar__item " + (props.activeElement === 'delete' ? 'toolbar__active' : '') } onClick={() => props.drawMode(drawMode.DELETE_MODE)}>
                <FontAwesomeIcon className="toolbar__icon" icon={faTrashAlt} size="2x" />
            </div>
        </div>
    );
}

export default Toolbar;