import React from 'react';
import { Document, Page } from 'react-pdf';

import Aux from '../../hoc/Aux/Aux';

const UploadDocument = (props) => {
    const noData = () => (
        <p className="no-file">Kies een bestand</p>
    )

    let filePDF = null; 

    if(props.pdfFile) {
        filePDF = props.pdfFile;
    } else {
        console.log('pdf is null');
    }

    return (
        <Aux>
            <Document 
                file={filePDF} 
                onLoadSuccess={props.onDocumentLoadSuccess}
                onSourceSuccess={props.onDocumentSourceSuccess}
                error="Het bestand voldoet niet aan de gestelde eisen."
                onLoadError={props.onLoadError}
                onSourceError={props.onSourceError}
                noData={noData}>
                <Page 
                    onRenderSuccess={props.onPDFRenderSuccess}
                    onLoadSuccess={props.onPageLoadSuccess}
                    pageNumber={1} 
                    scale={1}/>
            </Document>
        </Aux>
    )
}

export default UploadDocument;