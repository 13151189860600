import React from 'react';

import Aux from '../../hoc/Aux/Aux';
// import classes from './Layout.css';
import Header from '../Layout/Header/Header';

const Layout = (props) => (
    <Aux>
        <Header />
        <main className="content">{props.children}</main>
    </Aux>
);

export default Layout;
