import React from 'react';
import Teams from '../Teams';

import Aux from '../../../hoc/Aux/Aux';

const TeamControls = (props) => {
    return (
        <Aux>
            <Teams
                activeTeam={props.activeTeam}
                newTeamButtonClick={props.newTeamButtonClick}
                paletteColorClick={props.paletteColorClick}
                nameInputChange={props.nameInputChangeHandler}
                activeStateHandler={props.activeStateHandler}
                teams={props.teams}
                deleteTeamHandler={props.deleteTeamHandler}
            />
        </Aux>
    );
};

export default TeamControls;
