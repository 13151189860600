import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SpaceNorm from '../../components/SpaceNorm/SpaceNorm';
import Navigation from '../../components/Navigation/Navigation';
import Aux from '../../hoc/Aux/Aux';
import Sider from '../../components/Layout/Sider/Sider';
import MyModal from '../../components/UI/Modal/Modal';

import * as spaceNormBuilderActions from '../../store/actions/spaceNormBuilder';
import * as floorPlannerBuilderActions from '../../store/actions/floorPlannerBuilder';

const SpaceNormBuilder = (props) => {
    const [modalShow, setModalShow] = useState(false);

    const resultsClickHandler = () => {
        props.history.push('/results');
    };

    useEffect(() => {
        //props.onFetchSpaceNorms();
        props.onFetchTeams();
    }, [])

    const onInfoClick = () => {
        setModalShow(true);
    }

    return (
        <Aux>
            <Sider>
                <MyModal 
                    show={modalShow} 
                    onHide={() => setModalShow(false)}
                    title="Werkpleknormeringen"
                    body={
                        <>
                        <p>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes,</p>
                        <div>
                            <div>
                                <p>rijkswerkplek</p>
                                <p class="">Binnen het Rijk geldt een standaard omvang voor de werkplek</p>
                            </div>
                            <p>12</p>
                        </div>
                        </>
                    } />
                <SpaceNorm
                    updateSurfaceHandler={props.onSurfaceAreaChanged}
                    updateFlexFactorHandler={props.onFlexFactorChanged}
                    spaceNorms={props.spaceNorms}
                    onInfoClick={onInfoClick}
                />
                <Navigation btnNext="naar resultaten" nextClickHandler={resultsClickHandler} />
            </Sider>
        </Aux>
    );
}

const mapStateToProps = (state) => {
    return {
        teams: state.floorBuilder.teams,
        spaceNorms: state.spaceNormBuilder.spaceNorms,
        loading: state.floorBuilder.loading,
        error: state.floorBuilder.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchTeams: () => dispatch(floorPlannerBuilderActions.fetchTeams()),
        onFetchSpaceNorms: () => dispatch(spaceNormBuilderActions.fetchSpaceNorms()),
        onSurfaceAreaChanged: (surfaceArea) => dispatch(spaceNormBuilderActions.updateSurfaceArea(surfaceArea)),
        onFlexFactorChanged: (flexFactor) => dispatch(spaceNormBuilderActions.updateFlexFactor(flexFactor))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpaceNormBuilder);
