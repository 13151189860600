import React from 'react';
import Aux from '../../hoc/Aux/Aux';

const DetermineScale = (props) => {
    const btnStyle = props.drawToolActive ? "" : "btn--not-active";
    const currentScale = props.scale ? props.scale.toFixed(2) : 1;

    return (
        <>
            <div>
                <div>
                    <h2>Invoeren schaal van de tekening</h2>
                    <p>Om de floorplantoolbox te kunnen gebruiken is het noodzakelijk om de schaal van de geselecteerde tekening te bepalen. Met deze schaal kan de floorplantoolbox de juiste oppervlakten berekenen.</p>
                    <p>Gebruik de tekentool om een referentielijn te tekenen en voeg vervolgens de daadwerkelijke maat in van deze referentielijn (in cm).</p>
                </div>
                <div style={{marginTop: 4+'rem'}}>
                    <h2>Schaal in tekening</h2>
                    <p>Voer hier de maat van de referentie zijde op</p>
                    <div className="info__input">
                        <input 
                            type="text" 
                            value={props.referenceSize}
                            onChange={(e) =>
                                props.onMeasureInputChange(e.target.value)
                            }
                            className="input" />
                        <p className="info__unit">cm</p>
                    </div>

                    <p>Schaal deze plattegrond op basis van maatvoering in het bestand. Is er geen
                        maatvoering aanwezig in het bestand? Maak dan gebruik van een kenmerkend 
                        onderdeel waarvan de maat bekend is (bijvoorbeeld een keukenkastje met standaard
                        maat 60 cm x 60 cm). Gebruik hiervoor de tekentool om een bekende zijde te definiëren.
                    </p>
                </div>
            </div>
        </>);
};

export default DetermineScale;