import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import Upload from '../../components/Upload/Upload';
import Aux from '../../hoc/Aux/Aux';
import Sider from '../../components/Layout/Sider/Sider';
import Content from '../../components/Layout/Content/Content';
import UploadDocument from '../../components/UploadDocument/UploadDocument';
import Navigation from '../../components/Navigation/Navigation';

import * as floorPlannerBuilderActions from '../../store/actions/floorPlannerBuilder';
import * as uploadFileActions from '../../store/actions/'; 
import { pdfjs } from 'react-pdf';
import MyModal from '../../components/UI/Modal/Modal';

pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const UploadBuilder = (props) => {
    const [pdfFile, setPdfFile] = useState(null);
    const [preview, setPreview] = useState(null);
    const [error, setError]     = useState('');
    const [disabled, setDisabled] = useState(true);
    const [modalShow, setModalShow] = useState(false);
    const [fileName, setFileName] = useState('geen bestand geselecteerd');

    useEffect(() => {
        if(typeof pdfFile === "string") {
            setPreview(<img alt="floorplan" src={pdfFile}/>);
        } else {
            setPreview(<UploadDocument
                pdfFile={pdfFile}
                onDocumentLoadSuccess={onDocumentLoadSuccess}
                onSourceSuccess={onDocumentSourceSuccess}
                onPDFRenderSuccess={onPDFRenderSuccess}
                onLoadError={onLoadError}
                onSourceError={onSourceError} />);
        }
    }, [pdfFile])

    const onFileChange = event => {
        setFileName(event.target.files[0].name);
        if(event.target.files[0].type === 'image/png') {
            setPdfFile(URL.createObjectURL(event.target.files[0]));
        } else {
            setPdfFile(event.target.files[0]);
        }
        if(pdfFile) {
            setDisabled(false);
        }
    };

    const onLoadError = (error) => {
        setDisabled(true);
    }

    const onSourceError = (error) => {
    }

    const onPDFRenderSuccess = (pdfImage) => {
        setDisabled(false);
    }

    const onDocumentLoadSuccess = (event) => {
    };

    const onDocumentSourceSuccess = () => {
        this.props.onGetPDF();
    }

    const onInfoClick = () => {
        setModalShow(true);
    }

    const onGoNextClickHandler = () => {
        if(pdfFile) {
            if(typeof pdfFile != "string") {
                const importPDFCanvas = document.querySelector('.react-pdf__Page__canvas');
                const pdfAsImageSrc = importPDFCanvas.toDataURL('image/svg+xml');

                // save pdfImage to local storage
                localStorage.setItem('pdfImage', pdfAsImageSrc);
                props.updatePdfImage(pdfAsImageSrc);
            } else {
                localStorage.setItem('pdfImage', pdfFile);
                props.updatePdfImage(pdfFile);
            }
            
            props.history.push('/determine-scale');
        } else {
                setError('Kies eerst een plattegrond uit');
        }
    }    

    return (
        <Aux>
            <Content>
                {preview}
            </Content>
            <Sider>
                <MyModal 
                    show={modalShow} 
                    onHide={() => setModalShow(false)} 
                    title="Upload een tekening"
                    body="Kies voor het uploaden een van de volgende bestandsvormen: PDF, JPG, JPEG, PNG</p>
                    <p>Let op: het bestand dient kleiner te zijn dan 5 MB" />
                <Upload 
                    onFileChange={onFileChange}
                    fileName={fileName}
                    errorMessage={error}
                    onInfoClick={onInfoClick} />
                <Navigation btnNext="volgende stap" disabled={disabled} nextClickHandler={onGoNextClickHandler} />
            </Sider>
        </Aux>
    );
};

const mapStateToProps = (state) => {
    return {
        selectedFile: state.floorBuilder.selectedFile,
        pdfFile: state.uploadBuilder.pdfFile,
        pdfImage: state.uploadBuilder.pdfImage,
        error: state.floorBuilder.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchTeams: ()                => dispatch(floorPlannerBuilderActions.fetchTeams()),
        updatePdfImage: (pdfImage)      => dispatch(uploadFileActions.updatePdfImage(pdfImage)),
        onSvgClicked: (teams, point)    => dispatch(floorPlannerBuilderActions.addFloorPoint(teams, point))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadBuilder);