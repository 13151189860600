import React from 'react';

const Team = (props) => {
    return (
        <div className="results__team">
            <div className="results__team-title" style={{ backgroundColor: props.color }}>
                <label
                    className="results__team-name">
                    {props.name}
                </label>
                <label className="results__amount">{props.area}<label className="results__unit">m<span className="squared">2</span></label></label>
            </div>

            <div className="results__team-details">
                <div className="results__team-details-line">
                    <label className="results__team-details-name">oppervlakte</label>
                    <label className="results__team-details-amount">{props.area}<label className="results__team-details-unit"> m<span className="squared">2</span></label></label>
                </div>
                <div className="results__team-details-line">
                    <label className="results__team-details-name">werkplekken</label>
                    <label className="results__team-details-amount">{props.workspot}<label className="results__team-details-unit"> wp</label></label>
                </div>
                <div className="results__team-details-line">
                    <label className="results__team-details-name">formatieplekken</label>
                    <label className="results__team-details-amount">{props.fte}<label className="results__team-details-unit"> fte</label></label>
                </div>
                
            </div>
        </div>
    )
}

export default Team;