import React, {useState} from 'react';
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet, Canvas, Image } from '@react-pdf/renderer';

import Aux from '../../hoc/Aux/Aux';
import Team from './Team';
import Summary from './Summary'

const Results = (props) => {
    let teams = [];
    let teamsPdf = [];

    const [pdfImg, setPdfImg] = useState('');

    // Create styles
    const styles = StyleSheet.create({
        page: {
            flexDirection: 'row',
            backgroundColor: '#18326d',
            color: '#FFF'
        },
        section: {
            margin: 10,
            padding: 10,
            flexGrow: 1
        },
        text: {
            color: 'red'
        },
        canvas: {
            width: "300px",
            height: "300px"
        },
        image: {
            width: 'auto'
        }
    });

    // async function getImageForPdf() {
    //     const imgPromis = await getResolvedImage(blobURL);
    // }

    // const getResolvedImage = async (src) => {
    //     return new Promise((resolve, reject) => {
    //         const img = new Image();
    //         img.src = src;
    //         img.onload = () => resolve(img);
    //         img.onerror = (reason) => reject(reason);
    //     });
    // };

    let totalArea = 0;
    let totalWorkspace = 0;
    let totalFte = 0;

    let svgElement = document.getElementById('svg_element');
    let blobURL = '';
    if(svgElement) {
        let clonedSvgElement = svgElement.cloneNode(true);

        let outerHTML = clonedSvgElement.outerHTML, blob = new Blob([outerHTML],{type:'image/svg+xml;charset=utf-8'});

        let URL = window.URL || window.webkitURL || window;
        blobURL = URL.createObjectURL(blob);
    }

    let image = document.createElement("img");
    image.onload = () => {
        let canvas = document.createElement('canvas');
        let {width, height} = svgElement.getBBox();

        canvas.width = width;
        canvas.height = height;

        let context = canvas.getContext('2d');
        context.drawImage(image, 0, 0, width, height );
        //  downloadImage(canvas); need to implement

        setPdfImg(canvas.toDataURL());

        //pdfImageView = <Image allowDangerousPaths debug={true} source={png} />;
    };
    image.src = blobURL;

    if(props.teams.length > 0) {
        Object.keys(props.teams)
            .forEach((teamKey) => {
                let currentTeam = props.teams[teamKey];
                let teamArea = 0;

                teamsPdf.push(<Text key={teamKey} style={styles.text}>{props.teams[teamKey].name}</Text>)

                if(props.teams[teamKey].floors) {
                    props.teams[teamKey].floors
                        .forEach((floor, floorKey) => {
                            teamArea += ((floor.floorArea * (props.scale * props.scale))/10000);
                                // <h1 key={teamKey + "_" + floorKey}>
                                //     {props.teams[teamKey].name} {floor.area}
                                // </h1>);

                            teamsPdf.push(<Text key={teamKey + "_" + floorKey} style={styles.text}>Floorarea: {floor.floorArea}</Text>)
                    })
                }

                let workspace = parseInt(teamArea / props.spaceNorms.surfaceArea);
                let fte = parseInt(teamArea / (props.spaceNorms.surfaceArea * props.spaceNorms.flexFactor));

                totalArea += teamArea;
                totalWorkspace += workspace;
                totalFte += fte;

                teams.push (<Team key={teamKey}
                    name={currentTeam.name} 
                    color={currentTeam.color} 
                    area={parseInt(teamArea)}
                    workspot={workspace}
                    fte={fte} />);
            });
    }

    const MyDoc = () => (
        <Document>
            <Page>
                <View>
                    <Image debug={false} source={pdfImg} />
                </View>
            </Page>
            <Page style={styles.page}>
                <View>
                    {teamsPdf}
                </View>
            </Page>
        </Document>
    );

    const App = () => (
        <div>
            <PDFDownloadLink document={<MyDoc />} className="btn--pdf-download-link" fileName="floorplantoolbox-example.pdf">
            {({ blob, url, loading, error }) => (loading ? 'Loading document...' : <button className="btn btn--blue">exporteer</button>)}
            </PDFDownloadLink>
        </div>
    );

    let thePDF = null; 

    if(props.pdfImage) {
        thePDF = <App />;
    }
    else {
        thePDF = <h1>Pdf is still loading...</h1>;
    }
    

    return ( 
        <Aux>
            <div>
                <div className="info">
                    <h3>Resultaten</h3>
                    <p>Overzicht en download</p>
                </div>

                {teams}
            </div>
            <div>
                <Summary totalArea={totalArea > 0 ? totalArea.toFixed(2) : 0} totalWorkspace={totalWorkspace} totalFte={totalFte} />
                {thePDF}
            </div>
        </Aux>
    );
};

export default Results; 
