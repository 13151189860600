import React, { useCallback, useEffect, useLayoutEffect, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';

import Aux from '../../hoc/Aux/Aux';
import Content from '../../components/Layout/Content/Content';
import Toolbar from '../../components/Toolbar/Toolbar';
// import FloorPlanner from '../../components/Floor/FloorPlanner';

import penTool from '../../assets/img/pen-tool.png';

import uuid from 'react-uuid';

import FloorLine from '../../components/Floor/FloorLine/FloorLine';

import * as drawMode from '../../store/actions/drawMode';
import * as helpers from '../../helpers/helpers';

const FloorBuilder = (props) => {
    // LOCAL STATE
    const [action, setAction] = useState('none');
    const [box, setBox] = useState({});
    const [points, setPoints] = useState([]);
    const [scalePoints, setScalePoints] = useState([]);
    const [selectedElement, setSelectedElement] = useState(null);

    const [selectedFloor, setSelectedFloor] = useState(null);
    const [floorId, setFloorId] = useState(-1);

    // SELECTORS
    const pdfImage = useSelector(state => state.uploadBuilder.pdfImage);
    const elements = useSelector(state => state.floorBuilder.elements);
    //const drawMode = useSelector(state => state.floorPlannerBuilder.drawMode);
    
    const tool              = useSelector(state => state.floorBuilder.tool);
    const activeTeamIndex   = useSelector(state => state.floorBuilder.activeTeamIndex);
    const teams             = useSelector(state => state.floorBuilder.teams);
    const referenceSize     = useSelector(state => state.floorBuilder.referenceSize);
    const currentScale      = useSelector(state => state.floorBuilder.scale);

    const curLocation       = props.history.location.pathname;
    const showToolbar       = curLocation.indexOf('start') > -1 ? true : false;
    
    const dispatch = useDispatch();

    useEffect(() => {
        props.updatePdfImage();
    }, []);

    useLayoutEffect(() => {
        const svg_element = document.getElementById('svg_element');
        const boudingBox = svg_element.getBoundingClientRect();

        setBox({top: boudingBox.top, left: boudingBox.left });

        while(svg_element.firstChild) {
            svg_element.removeChild(svg_element.firstChild);
        }

        // redraw the floorplan
        const image = document.createElementNS('http://www.w3.org/2000/svg', 'image');
        image.setAttributeNS(null, 'href', pdfImage);
        image.setAttributeNS(null, 'width', "100%");
        image.setAttributeNS(null, 'height', "100%");
        svg_element.appendChild(image);

        let teamElements = [];
        teams.forEach((team) => {
            team.floors.forEach(floor => {
                teamElements.push({...floor, color: team.color})
            })
        });

        elements.length && elements.forEach(element => {
            if(element.theElement.props.type === 'scale') {
                const parsedPoints = element.theElement.props.points
                    .map((el) => {
                        return el.x + ' ' + el.y;
                    })
                    .join(',');

                const polyline = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');
                polyline.setAttributeNS(null, 'stroke', element.theElement.props.color);
                polyline.setAttributeNS(null, 'stroke-width', "3");
                polyline.setAttributeNS(null, 'fill', "none");
                polyline.setAttributeNS(null, 'points', parsedPoints);
                svg_element.appendChild(polyline);
            }
        })
        teamElements.forEach(element => {
            if(element.theElement.type === 'rect') {
                
                const rect = document.createElementNS('http://www.w3.org/2000/svg', 'rect');
                rect.setAttributeNS(null, 'x', element.theElement.props.x);
                rect.setAttributeNS(null, 'y', element.theElement.props.y);
                rect.setAttributeNS(null, 'width', element.theElement.props.width);
                rect.setAttributeNS(null, 'height', element.theElement.props.height);
                rect.setAttributeNS(null, 'stroke', element.color);
                rect.setAttributeNS(null, 'stroke-width', "3");
                rect.setAttributeNS(null, 'fill', element.color);
                rect.setAttributeNS(null, 'fill-opacity', "0.75");
                svg_element.appendChild(rect)
            }
            else if(element.type === 'polyline') {
                console.log('draw polyline');
                const parsedPoints = element.theElement.props.points
                    .map((el) => {
                        return el.x + ' ' + el.y;
                    })
                    .join(',');

                const polyline = document.createElementNS('http://www.w3.org/2000/svg', 'polyline');
                polyline.setAttributeNS(null, 'stroke', element.theElement.props.color);
                polyline.setAttributeNS(null, 'stroke-width', "3");
                polyline.setAttributeNS(null, 'fill', "none");
                polyline.setAttributeNS(null, 'points', parsedPoints);
                svg_element.appendChild(polyline);
            }
            else if(element.theElement.type === 'polygon') {
                const parsedPoints = element.theElement.props.points
                    .map((el) => {
                        return el.x + ' ' + el.y;
                    })
                    .join(',');

                const polygon = document.createElementNS('http://www.w3.org/2000/svg', 'polygon');
                polygon.setAttributeNS(null, 'stroke', element.color);
                polygon.setAttributeNS(null, 'stroke-width', "5");
                polygon.setAttributeNS(null, 'fill', element.color);
                polygon.setAttributeNS(null, 'fill-opacity', "0.75");
                polygon.setAttributeNS(null, 'points', parsedPoints);
                svg_element.appendChild(polygon);
            }
        });

    }, [teams, elements, pdfImage]);

    function createElement(id, x1, y1, x2, y2, type) {
        let theElement = 'polygon';
        let floorArea = 0;

        if(type === 'rect') {
            const width = Math.abs(x2 - x1);
            const height = Math.abs(y2 - y1);
            let newX = x2;
            let newY = y2;

            if(y2 > y1) {
                newY = y1;
            }
    
            if(x2 > x1) {
                newX = x1;
            }

            theElement = createRectangle(newX, newY, width, height);
            floorArea = width * height;
        } else if(type === 'polygon') {
            theElement = createPolygon(x1, y1);
            floorArea = area(points);
            console.log(floorArea);
        } else if(type === 'scale') {
            theElement = createLineForScale(x1, y1, x2, y2);
        } else if(type === 'polyline') {
            theElement = createPolyline(x1, y1, x2, y2);
            floorArea = area(points);
            console.log(floorArea);
        }

        return {id, x1, y1, x2, y2, type, floorArea, theElement}
    }

    function relativeCoordinates(event) {
        const top = box.top;
        const left = box.left;
        const clientX = (event.clientX - left);
        const clientY = (event.clientY - top);

        return {clientX, clientY}
    }

    function createLineForScale(x, y, x2, y2) {
        return <FloorLine points={[{x, y}, {x: x2, y: y2}]} color="orange" type={tool} />;
    }

    function createPolyline(x1, y1, x2, y2) {
        const newPoint = {x: x2, y: y2};
        const myCurrentPoints = [...points, newPoint];
        setPoints([...points, newPoint]);

        return <FloorLine points={myCurrentPoints} color="red" type={tool} />;
    }

    function createPolygon(x, y) {
        setPoints([...points, {x, y}]);

        return <polygon
                fill={"black"}
                fillOpacity={0.8}
                points={points}
                strokeWidth="3" />
    }

    function createRectangle(x, y, width, height) {
        return <rect 
            fillOpacity={0.8}
            stroke={"red"}
            x={x}
            y={y}
            width={width}
            height={height}
            strokeWidth="3" />
    }

    const isWithinElement = (x, y, element) => {
        const { type, x1, x2, y1, y2 } = element;

        if(type === 'rect') {
            const minX = Math.min(x1, x2);
            const maxX = Math.max(x1, x2);
            const minY = Math.min(y1, y2);
            const maxY = Math.max(y1, y2);

            return x >= minX && x <= maxX && y >= minY && y <= maxY;
        } else if (type === 'polygon') {
            const vertices = element.theElement.props.points.length;
            const xVert = element.theElement.props.points.map(el => el.x);
            const yVert = element.theElement.props.points.map(el => el.y);
            
            return isPointInPolygon(vertices, xVert, yVert, x, y);
        } else {
            console.log('winthin something else');
            const a = {x: x1, y: y1};
            const b = {x: x2, y: y2};
            const c = {x,y};
            const offset = distance(a,b) - (distance(a, c) + distance(b,c));
            return Math.abs(offset) < 1;
        }
    }

    const distance = (a,b) => {
        return Math.sqrt(Math.pow(a.x - b.x, 2) + Math.pow(a.y - b.y, 2))};

    const area = (points) => {
        let a = 0;
    
        const firstPoint = points[0];
    
        points.forEach((element, index) => {
            const currentPoint = element;
            const secondPoint = points[index + 1] || firstPoint;
            a += currentPoint.x * secondPoint.y - currentPoint.y * secondPoint.x;
        });
    
        return Math.abs(a) / 2;
    }

    const isPointInPolygon = (numberOfVertices, xVertices, yVertices, testX, testY) => {
        let i, j = 0;
        let inPolygon = false;
        for(i = 0, j = numberOfVertices - 1; i < numberOfVertices; j = i++) {
            if( ((yVertices[i] > testY) !== (yVertices[j] > testY)) &&
                (testX < (xVertices[j] - xVertices[i]) * (testY - yVertices[i]) / (yVertices[j] - yVertices[i]) + xVertices[i]))
                inPolygon = !inPolygon;
        }
        return inPolygon;
    }

    const getElementAtPosition = (x, y, elements) => {
        return elements.find(element => isWithinElement(x, y, element));
    };

    const handleMouseDown = (event) => {
        const { clientX, clientY } = relativeCoordinates(event);
        const id = uuid();

        let element = createElement(id, clientX, clientY, clientX, clientY, tool);

        if(tool === 'scale') {
            let newPoints = [];
            let newElements = [];
            
            // add the new points
            newPoints.push({x: clientX, y: clientY});
            newElements.push(element);

            let currentLine = newElements[0];
            if(currentLine) {
                currentLine.theElement.props.points.push({x: clientX, y: clientY});
                newElements[0] = currentLine;
            }

            // start again
            if(newPoints.length > 2) {
                newPoints = [];
                newPoints.push({x: clientX, y: clientY});
                newElements = [];
            }

            setScalePoints(newPoints);
            // setElements(newElements);
            dispatch({type: 'SET_ELEMENTS', payload:newElements});
            setAction('drawing');
        } else if(tool === 'rect') {
            addFloorToTeam(element);
            setFloorId(element.id);
            dispatch({type: 'SET_ELEMENTS', payload: element});

            setAction('drawing');
        } else if(tool === 'polyline') {
            let tempElement = unfinishedPolygon();
            if(tempElement) {
                console.log('yes we have');
                element = tempElement;
            }

            if(element) {
                element.theElement.props.points.push({x: clientX, y: clientY});

                const newPoint = {x: clientX, y: clientY};
                const firstPoint = {x: element.x1, y: element.y1};
                const bla = distance(firstPoint, newPoint);

                setPoints(prevState => [...prevState, newPoint])

                if(points.length > 1 && bla < 10) {
                    // convert polyline to polygon and change type
                    element.theElement = <polygon
                        fill={"black"}
                        fillOpacity={0.8}
                        points={points}
                        strokeWidth="3" />

                    element.type = 'polygon';

                    // empty points
                    setPoints([]);
                }

                addFloorToTeam(element);
                setFloorId(element.id);
                dispatch({type: 'SET_ELEMENTS', payload: element});
            } else {
                element.theElement.props.points.push({x: clientX, y: clientY});
                dispatch({type: 'SET_ELEMENTS', payload: element});
            }

            setAction('drawing');
        } else if(tool === 'delete') {
            let allFloors = [];
            teams.forEach(team => allFloors.push(...team.floors));
            const element = getElementAtPosition(clientX, clientY, allFloors);

            if(element) {
                dispatch({type: 'DELETE_ELEMENT', payload: element.id})
            }
        } else if(tool === 'selection') {
            let allFloors = []
            teams.forEach(team => allFloors.push(...team.floors));
            const element = getElementAtPosition(clientX, clientY, allFloors);

            if(element) {
                const offsetX = clientX - element.x1;
                const offsetY = clientY - element.y1;
                setSelectedFloor({...element, offsetX, offsetY});
                setAction('moving')
            }
        }
    };

    const unfinishedPolygon = () => {
        if (!teams) {
            return;
        }
        const activeTeam = teams.find(x => x.id === activeTeamIndex);
        if(activeTeam) {
            const floor = activeTeam.floors.find(floor => floor.type === 'polyline');
            return floor;
        }

        return null;
    }

    const addFloorToTeam = (element) => {
        const newTeams = Object.assign([], teams);
        const newPoint = points;

        if (activeTeamIndex === -1 || teams.length === 0) {
            return newTeams;
        }

        const index = newTeams.findIndex(x => x.id === activeTeamIndex);
        const newTeamFloors = newTeams[index].floors ? newTeams[index].floors : [];

        // new floor or updated floor?
        const floorIndex = newTeamFloors.findIndex(floor => floor.id === element.id);

        if(floorIndex !== -1) {
            newTeamFloors[floorIndex] = element;
        } else {
            // add element to the floor array of the team
            newTeamFloors.push(element);
        }

        addElement(newTeams);
    }

    const determineScale = () => {
        const index = elements.findIndex(element => element.theElement.props.type === 'scale');
        const element = elements[index];

        const newPoint = element.theElement.props.points[1];
        const firstPoint = element.theElement.props.points[0];
        
        const d = distance(firstPoint, newPoint);
        updateDistance(d);
        
        const scale = referenceSize / d;
        updateScale(scale);

        // empty scale points
        setScalePoints([]);
    }
    
    const handleMouseUp = () => {
        setAction('none');
        setSelectedElement(null);

        if(tool === 'scale') {
            determineScale();
        }
    };
    
    const handleMouseMove = (event) => {
        const { clientX, clientY } = relativeCoordinates(event);

        // let allFloors = []
        // teams.forEach(team => allFloors.push(...team.floors));

        // if(tool === 'scale') {
        //     // event.target.style.cursor = "url(img/pen-tool.png), auto;";
        //     //event.target.style.cursor.style.background = "red";
        // }

        // if(tool === 'selection') {
        //     event.target.style.cursor = getElementAtPosition(clientX, clientY, allFloors) ? 'move' : 'default';
        // }

        if(action === 'drawing') {

            if(tool === 'scale') {
                const index = elements.length -1;
                const {x1, y1} = elements[index];
                updateElement(index, x1, y1, clientX, clientY, tool);
            } else if ((activeTeamIndex !== -1 || teams.length !== 0)) {
                // return;
                const team = teams.find(team => team.id === activeTeamIndex);
                const floor = team.floors.find(floor => floor.id === floorId);

                const { x1, y1 } = floor;
                updateFloor(floor.id, x1, y1, clientX, clientY, tool);
            }
        } else if (action === 'moving') {
            const { id, x1, x2, y1, y2, type, offsetX, offsetY } = selectedFloor;

            if(type === 'rect') {
                const width  = x2 - x1;
                const height = y2 - y1;
                const newX1 = clientX - offsetX;
                const newY1 = clientY - offsetY;
                updateFloor(id, newX1, newY1, newX1 + width, newY1 + height, type);
            } else if(type === 'polyline') {
                // console.log('move polygon', offsetX, offsetY)
            }
            
        }
    };

    const updateFloor = (id, x1, y1, x2, y2, type) => {
        const updatedFloor = createElement(id, x1, y1, x2, y2, type);

        addFloorToTeam(updatedFloor);
    }

    const updateElement = (id, x1, y1, x2, y2, type) => {
        const updatedElement = createElement(id, x1, y1, x2, y2, type);
            
        const elementsCopy = [...elements];
        elementsCopy[id] = updatedElement;
        dispatch({type: 'SET_ELEMENTS', payload: elementsCopy});
        // setElements(elementsCopy);
    }

    const addElement = useCallback(
        (teams) => dispatch({type: 'ADD_ELEMENT', teams}), [dispatch]
    )

    const changeElementType = useCallback(
        (elem) => dispatch({type: 'SET_ELEMENT_TYPE', elementType: elem}), [dispatch]
    )

    const updateScale = useCallback(
        (scale) => dispatch({type: 'UPDATE_SCALE', scale}), [dispatch]
    )

    const updateDistance = useCallback(
        (distance) => dispatch({type: 'UPDATE_DISTANCE', distance}), [dispatch]
    )

    // const updateReferenceSize = useCallback(
    //     (referenceSize) => dispatch({type: 'UPDATE_REFERENCE_SIZE', referenceSize})
    // )

    const handleDrawModeChange = (event) => {
        switch (event) {
            case drawMode.SQUARE_MODE:
                changeElementType('rect');
                break;
            case drawMode.POLYGON_MODE:
                changeElementType('polyline');
                break;
            case drawMode.DELETE_MODE:
                changeElementType('delete');
                break;
            case drawMode.SELECTION_MODE:
                changeElementType('selection');
                break;
            case drawMode.SCALE_MODE:
                changeElementType('scale');
                break;
            default:
                break;
        }
    }

    return (
        <Aux>
            <Content scale={currentScale}>
                <Toolbar showToolbar={showToolbar} activeElement={tool} drawMode={handleDrawModeChange}/>

                <svg 
                    xmlns="http://www.w3.org/2000/svg" 
                    xmlnsXlink="http://www.w3.org/1999/xlink" 
                    xmlSpace="preserve"
                    id="svg_element" 
                    className="my_svg_element"
                    viewBox="0 0 100px 100px" preserveAspectRatio="xMidYMid meet"
                    onMouseDown={handleMouseDown}
                    onMouseMove={handleMouseMove}
                    onMouseUp={handleMouseUp}
                    style={{width: "100%", height: "calc(100% - 52px)"}}>
                        <image href={pdfImage} width="100%" height="100%" />
                </svg>
            </Content>
        </Aux>
    );
}

const mapStateToProps = (state) => {
    return {
        //elementType: state.floorBuilder.elementType
        //pdfImage: state.uploadBuilder.pdfImage
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        getPdfImage: () => dispatch({type: 'GET_PDF_IMAGE'}),
        updatePdfImage: () => dispatch({type: 'UPDATE_PDF_IMAGE'})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FloorBuilder);