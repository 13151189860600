import * as actionTypes from '../actions/actionTypes';
import { updateObject } from "../utility";
import * as drawMode from '../../store/actions/drawMode';

const initialState = {
    teams: [],
    elements: [],
    activeTeamIndex: -1,
    spaceNorms: {surfaceArea: 4, flexFactor: 2},
    canvas: null,
    loading: false,
    distance: 0,
    error: false,
    scale: 1,
    selectedFile: null,
    drawMode: null,
    tool: 'scale',
    referenceSize: 1,
    showToolbar: false
};

const floorBuilder = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_ELEMENT_TYPE:
            return {
                ...state,
                tool: action.elementType
            }
        case actionTypes.SET_DRAW_MODE:
            return {
                ...state,
                drawMode: action.drawMode,
                tool: 'scale'
            }
        case actionTypes.UPDATE_SCALE:
            return {
                ...state,
                scale: action.scale
            }
        case actionTypes.UPDATE_DISTANCE:
            return {
                ...state,
                distance: action.distance
            }
        case actionTypes.UPDATE_REFERENCE_SIZE:
            return {
                ...state,
                referenceSize: action.referenceSize,
                scale: action.referenceSize/state.distance
            }
        case actionTypes.UPDATE_REFERENCE_SIZE_CLOUD:
            return {
                ...state,
                referenceSize: action.referenceSize
            }
        case actionTypes.UPDATE_ACTIVE_TEAM_INDEX:
            return {
                ...state,
                activeTeamIndex: action.teamId
            }
        case actionTypes.ADD_TEAM:
            const newTeam = {
                id: action.teamId,
                active: true,
                name: 'team ' + (state.teams.length+1),
                color: 'red',
                floors: [],
            };

            return {
                ...state,
                teams: state.teams.concat(newTeam),
                activeTeamIndex: action.teamId
            }
        case actionTypes.DELETE_TEAM:
            const setActiveTeamIndex = (state.teams.filter(el => el.id !== action.teamId).length === 0 ? false : true )
            return {
                ...state,
                teams: state.teams.filter((el) => el.id !== action.teamId),
                activeTeamIndex: setActiveTeamIndex ? state.activeTeamIndex : -1
            }
        case actionTypes.ADD_ELEMENT:
            return {
                ...state,
                teams: action.teams
            }
        case actionTypes.CHANGE_TEAM_NAME:
            const updatedTeams = state.teams.map(el => {
                return (
                    el.id === state.activeTeamIndex
                    ? { ...el, name: action.teamName }
                    : { ...el }
                )
            });

            return {
                ...state,
                teams: updatedTeams
            }
        case actionTypes.CHANGE_TEAM_COLOR:
            const updatedTeamsColor = state.teams.map(el => {
                return (
                    el.id === state.activeTeamIndex
                    ? { ...el, color: action.teamColor }
                    : { ...el }
                )
            });

            return {
                ...state,
                teams: updatedTeamsColor
            }
        case actionTypes.DELETE_ELEMENT:
            const updatedTeamElements = state.teams;
            
            updatedTeamElements.filter(el => {
                if(el.floors) {
                    el.floors.filter((fl, index) => {
                        if(fl.id === action.payload) {
                            el.floors.splice(index, 1);
                        }
                    })
                }
            });

            return {
                ...state,
                teams: updatedTeamElements,
                elements: []
            }
        case actionTypes.SET_ELEMENTS:
            return {
                ...state,
                elements: action.payload
            }
        case actionTypes.SHOW_TOOLBAR:
            return {
                ...state,
                showToolbar: action.payload
            }
        default:
            break;
    }

    return state;
}

export default floorBuilder;