import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';

import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

import floorPlannerBuilderReducer from './store/reducers/floorPlannerBuilder';
import spacenormBuilderReducer from './store/reducers/spaceNormBuilder';
import uploadBuilderReducer from './store/reducers/uploadBuilder';
import scaleBuilderReducer from './store/reducers/scaleBuilder';
import floorBuilderReducer from './store/reducers/floorBuilder';

const rootReducer = combineReducers({
    //floorPlannerBuilder: floorPlannerBuilderReducer,
    spaceNormBuilder: spacenormBuilderReducer,
    uploadBuilder: uploadBuilderReducer,
    scaleBuilder: scaleBuilderReducer,
    floorBuilder: floorBuilderReducer
})


// const logger = store => {
//     return next => {
//         return action => {
//             console.log('[Middleware] Dispatching', action);
//             const result = next(action);
//             console.log('[Middleware] next state', store.getState());
//             return result;
//         }
//     }
// };

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;


const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));

const app = (
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
