import * as actionTypes from '../actions/actionTypes';

const initialState = {
    //teams: [],
    spaceNorms: {surfaceArea: 4, flexFactor: 2},
    //loading: false,
    //error: false
}

const spaceNormBuilder = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.SET_SPACE_NORMS:
            return {
                ...state,
                loading: false,
                spaceNorms: { flexFactor: action.spaceNorms.flexFactor, surfaceArea: action.spaceNorms.surfaceArea }
            }
        case actionTypes.UPDATE_SURFACE_AREA:
            return {
                ...state,
                loading: false,
                spaceNorms: {...state.spaceNorms, surfaceArea: action.surfaceArea}
            }
        case actionTypes.UPDATE_SURFACE_AREA_FAILED:
            return {
                ...state,
                error: true
            }
        case actionTypes.UPDATE_FLEX_FACTOR:
            return {
                ...state,
                loading: false,
                spaceNorms: {...state.spaceNorms, flexFactor: action.flexFactor}
            }
        case actionTypes.UPDATE_FLEX_FACTOR_FAILED:
            return {
                ...state,
                error: true
            }
        default:
            break;
    }
    return state;
}

export default spaceNormBuilder;