import React from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalTitle from 'react-bootstrap/ModalTitle';
import ModalBody from 'react-bootstrap/ModalBody';

const MyModal = (props) => {
    return (
        <Modal show={props.show} centered onHide={props.onHide}>
            <ModalHeader closeButton>
                <ModalTitle>{props.title}</ModalTitle>
            </ModalHeader>
            <ModalBody>{props.body}</ModalBody>
        </Modal>
    )
}

export default MyModal;