import React, { useState, useEffect } from 'react';
import { connect, useSelector, useDispatch } from 'react-redux';

import Aux from '../../hoc/Aux/Aux';
import Navigation from '../../components/Navigation/Navigation';
import Sider from '../../components/Layout/Sider/Sider';
import * as actionTypes from '../../store/actions/actionTypes';

import * as scaleBuilderActions from '../../store/actions/scaleBuilder';
import * as floorBuilder from '../../store/actions/floorBuilder';

import * as drawMode from '../../store/actions/drawMode'

import DetermineScale from '../../components/DetermineScale/DetermineScale';

const ScaleBuilder = (props) => {
    const [drawToolActive, setDrawToolActive] = useState(false);
    const drawMode2 = useSelector(state => state.floorBuilder.drawMode);
    const dispatch = useDispatch();

    useEffect(() => {
        props.setDrawMode('scale');
        // dispatch(props.setDrawMode(drawMode.SCALE_MODE))
    }, [])

    const onDrawToolClickHandler = () => {
        setDrawToolActive(!drawToolActive)
    }

    const onReferenceSizeChangeHandler = (referenceSize) => {
        props.onSaveReferenceSize(referenceSize);
    }

    const onGoNextClickHandler = () => {
        props.onSaveTempPoints([]);
        
        props.setDrawMode(drawMode.POLYGON_MODE);
        // save the reference size in the cloud
        props.onSaveReferenceSizeCloud(props.referenceSize);
        dispatch({type: actionTypes.SET_ELEMENT_TYPE, payload: ''});
        dispatch({type: actionTypes.SET_ELEMENTS, payload: []});
        dispatch({type: actionTypes.SHOW_TOOLBAR, payload: true});

        props.history.push('/start');
    }

    return (
        <Aux>
            <Sider>
                <DetermineScale 
                    referenceSize={props.referenceSize}
                    onDrawToolClick={onDrawToolClickHandler}
                    drawToolActive={drawToolActive}
                    onMeasureInputChange={onReferenceSizeChangeHandler}
                    scale={props.scale} />
                <Navigation btnNext="volgende stap" nextClickHandler={onGoNextClickHandler} />
            </Sider>
        </Aux>
    );
}

const mapStateToProps = (state) => {
    return {
        teams: state.floorBuilder.teams,
        spaceNorms: state.spaceNormBuilder.spaceNorms,
        pdfImage: state.uploadBuilder.pdfImage,
        scale: state.floorBuilder.scale,
        referenceSize: state.floorBuilder.referenceSize,
        distance: state.floorBuilder.distance,
        loading: state.floorBuilder.loading,
        error: state.floorBuilder.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        //onScaleClicked: (newScale)  => dispatch(floorPlannerBuilderActions.saveScale(newScale)),
        // onGetPDF: ()                            => dispatch(uploadBuilderActions.getPDFImage()),
        onSaveReferenceSize: (referenceSize)            => dispatch(floorBuilder.saveReferenceSize(referenceSize)),
        onSaveReferenceSizeCloud: (referenceSize)       => dispatch(floorBuilder.saveReferenceSizeCloud(referenceSize)),
        onSaveTempPoints: (points)                      => dispatch(scaleBuilderActions.saveTempPoints(points)),
        setDrawMode: (drawMode)                         => dispatch(floorBuilder.setDrawMode(drawMode))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ScaleBuilder);