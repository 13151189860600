import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/img/logo.svg';

const splashScreen = () => {

    return (
        <div className="splash-screen">
            <div className="splash-screen__title">
                <img src={Logo} alt="Floor Plan Toolbox Logo" />
                <Link to="/loadplan" className="btn btn--splash">Klik om te beginnen</Link>
            </div>

            <footer className="splash-screen__footer">
                <p>FloorPlanToolbox is een slimme en makkelijk te bedienen tool bij het maken van indelingstekeningen op plattegronden.</p>

                <ul className="splash-screen__list">
                    <li><Link to="#" className="splash-screen__list-item">Copyright {new Date().getFullYear()} Floor Plan Generator</Link></li>
                    <li><Link to="#" className="splash-screen__list-item">Disclaimer</Link></li>
                    <li><Link to="#" className="splash-screen__list-item">Privacy Statement</Link></li>
                </ul>
            </footer>
        </div>
    );
}

export default splashScreen;