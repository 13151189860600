import React from 'react';
import { Route, Switch } from 'react-router-dom';
import './App.css';
import './css/main.css';
// import 'bootstrap/dist/css/bootstrap.min.css';

import Layout from './components/Layout/Layout';
import SplashScreen from './components/splashScreen';
// import FloorPlannerBuilder from './containers/FloorPlannerBuilder/FloorPlannerBuilder';
import FloorBuilder from './containers_hooks/FloorBuilder/FloorBuilder';
import ControlsBuilder from './containers/ControlsBuilder/ControlsBuilder';
import SpaceNormBuilder from './containers/SpaceNormBuilder/SpaceNormBuilder';
import ResultsBuilder from './containers/ResultsBuilder/ResultsBuilder';
// import UploadBuilder from './containers/UploadBuilder/UploadBuilder';
import UploadBuilderHooks from './containers_hooks/UploadBuilder/UploadBuilder';
import ScaleBuilder from './containers/ScaleBuilder/ScaleBuilder';

const App = () => {
    return <div>
    <Switch>
        <Route path="/" exact component={SplashScreen} />
        <Route>
            <Layout>
                <Route path="/loadplan" exact component={UploadBuilderHooks} />
                {/* <Route path={["/determine-scale", "/start", "/norm-bepaling", "/results"]} component={FloorPlannerBuilder} /> */}
                <Route path={["/determine-scale", "/start", "/norm-bepaling", "/results"]} component={FloorBuilder} />
                <Route path="/determine-scale" component={ScaleBuilder} />
                <Route path="/start" component={ControlsBuilder} />
                <Route path="/norm-bepaling" component={SpaceNormBuilder} />
                <Route path="/results" component={ResultsBuilder} />
            </Layout>
        </Route>
    </Switch>
</div>
}

export default App;
