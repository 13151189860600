import React, { useState } from 'react';
import Aux from '../../hoc/Aux/Aux';
import infoIcon from '../../assets/img/info-icon.svg';

const Upload = (props) => {
    const errorMessage = props.errorMessage ? <h2 style={{color: 'red'}}>{props.errorMessage}</h2> : '';
    
    return (
        <Aux>
            <div>
                <h2>Welkom bij de Floor Plan Toolbox</h2>
                <p>Start hier met het creëren van de indelingstekeningen.</p>

                <div className="custom-file-upload">
                    <label className="custom-file-upload__label">
                        <input type="file" onChange={props.onFileChange} />
                        kies bestand
                    </label>
                    <div className="custom-file-upload__file-name"><label>{props.fileName}</label></div>
                    <img className="custom-file-upload__info" onClick={props.onInfoClick} src={infoIcon} alt="info"/>
                </div>

                {errorMessage}

            </div>
        </Aux>
    );
};

export default Upload;