import React from 'react';

const Summary = (props) => {
    return (
        <div className="summary">
            <div className="summary__line">
                <h3>totaal oppervlakte</h3>
                <p className="summary__amount">{props.totalArea}<span className="summary__unit"> m<span className="squared">2</span></span></p>
            </div>
            <div className="summary__line">
                <h3>totaal werkplekken</h3>
                <p className="summary__amount">{props.totalWorkspace} <span className="summary__unit"> wp</span></p>
            </div>
            <div className="summary__line">
                <h3>totaal fte</h3>
                <p className="summary__amount">{props.totalFte} <span className="summary__unit"> fte</span></p>
            </div>
        </div>
    );
}

export default Summary;