import * as actionTypes from '../actions/actionTypes';

const initialState = {
    selectedFile: null,
    pdfImage: 'bla'
}

const uploadBuilder = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                selectedFile: action.selectedFile,
                pdfImage: action.pdfImage
            }
        case actionTypes.FETCH_PDF_SUCCESS:
            return {
                ...state,
                pdfImage: action.pdfImage
            }
        case actionTypes.SAVE_PDF_SUCCESS:
            return {
                ...state,
                pdfFile: action.pdfFile
            }
        case actionTypes.GET_PDF_FILE_SUCCESS:
            return {
                ...state,
                pdfFile: action.pdfFile
            }
        case actionTypes.GET_PDF_IMAGE_SUCCESS:
            return {
                ...state,
                pdfImage: action.pdfImage
            }
        case 'UPDATE_PDF_IMAGE':
            return {
                ...state,
                pdfImage: localStorage.getItem('pdfImage')
            }
        default:
            break;
    }

    return state;
}

export default uploadBuilder;