import React from 'react';
import TeamControls from '../../components/Teams/TeamControls/TeamControls';
import { connect, useDispatch, useSelector } from 'react-redux';

import uuid from 'react-uuid';

import Navigation from '../../components/Navigation/Navigation';
import * as controlsBuilderActions from '../../store/actions/index';
import * as floorBuilderActions from '../../store/actions/floorBuilder';
import Aux from '../../hoc/Aux/Aux';
import Sider from '../../components/Layout/Sider/Sider';

const ControlsBuilder = (props) => {
    //const [loading, setLoading] = useState(false);
    //const [error, setError] = useState(false);

    const activeTeamIndex = useSelector(state => state.floorBuilder.activeTeamIndex);
    const teams = useSelector(state => state.floorBuilder.teams);

    const dispatch = useDispatch();

    const determineSpaceNormHandler = () => {
        // console.clear();
        props.history.push('/norm-bepaling');
    };

    let teamControls = <TeamControls
            activeTeam={activeTeamIndex}
            newTeamButtonClick={props.onTeamAdded}
            paletteColorClick={props.onChangeTeamColor}
            nameInputChangeHandler={props.onChangeTeamName}
            activeStateHandler={props.updateActiveTeamIndex}
            deleteTeamHandler={props.onTeamDeleted}
            teams={teams} />

    return (
        <Aux>
            <Sider>
                {teamControls}
                <Navigation btnNext="volgende stap" nextClickHandler={determineSpaceNormHandler} />
            </Sider>
        </Aux>
    );
}

const mapStateToProps = (state) => {
    return {
        teams: state.floorBuilder.teams,
        loading: state.floorBuilder.loading,
        error: state.floorBuilder.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchTeams: ()                => dispatch(controlsBuilderActions.fetchTeams()),
        onTeamAdded: (newTeam)             => dispatch(floorBuilderActions.addTeam(newTeam, uuid())),
        onTeamDeleted: (id)             => dispatch(floorBuilderActions.deleteTeam(id)),
        onTeamActive: (id)              => dispatch(controlsBuilderActions.activateTeam(id)),
        onChangeTeamName: (name)    => dispatch(floorBuilderActions.changeTeamName(name)),
        onChangeTeamColor: (color)  => dispatch(floorBuilderActions.changeTeamColor(color)),
        updateActiveTeamIndex: (teamId) => dispatch(floorBuilderActions.updateActiveTeamIndex(teamId))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ControlsBuilder);
