import React from 'react';

import Team from '../Teams/Team/Team';

const Teams = (props) => {
    let teamsLength = 1; //props.teams.length + 1;
    if(props.teams) {
        teamsLength = props.teams.length + 1;
    }

    let teams = [];
    if(props.teams) {

        props.teams.map((team, index) => {
                teams.push(<Team
                    key={index}
                    identifier={team.id}
                    team={team}
                    active={team.id === props.activeTeam}
                    colorClick={props.paletteColorClick}
                    valChange={props.nameInputChange}
                    activeStateHandler={props.activeStateHandler}
                    deleteTeamHandler={props.deleteTeamHandler}
                />)
            }
        )
    }    

    return (
        <div>
            <h2>Bepaal nu de oppervlakten voor de verschillende teams</h2>
            <p>
                Creër een team en teken de daarbijbehorende polygonen op de
                plattegrond. Maak hiervoor gebruik van de pentool of de
                vormtool.
            </p>

            {teams}

            <button
                className="btn btn--new-team"
                onClick={(newTeam) => props.newTeamButtonClick()}
            >
                nieuw team
            </button>
        </div>
    );
};

export default Teams;
