// import * as actionTypes from './actionTypes';
// import axios from '../../axios-floors';

export const updatePdfImage = (pdfImage) => {
    return {
        type: 'UPDATE_PDF_IMAGE',
        pdfImage
    }
}

// export const fetchPDF = () => {
//     return (dispatch, getState) => {
//         axios.get('/pdf.json')
//             .then(response => {
//                 dispatch(fetchPDFSuccess(response.data));
//             })
//             .catch(error => {
//                 dispatch(fetchPDFFailed(error));
//             });


//         //const pdfImage = JSON.parse(localStorage.getItem('pdfImage'));
//         //dispatch(getPDFImageSuccess(pdfImage));
//     }
// }

// export const fetchPDFSuccess = (pdfImage) => {
//     return {
//         type: actionTypes.FETCH_PDF_SUCCESS,
//         pdfImage
//     }
// }

// export const fetchPDFFailed = () => {
//     return {
//         type: actionTypes.FETCH_PDF_FAILED
//     };
// };

// export const getPDFFile = () => {
//     return (dispatch) => {
//         const pdfFile = localStorage.getItem('pdfFile');
//         dispatch(getPDFFileSuccess(pdfFile));

//     }
// }

// export const getPDFImage = () => {
//     return (dispatch) => {
//         //const pdfImage = JSON.parse(localStorage.getItem('pdfImage'));
//         const pdfImage = localStorage.getItem('pdfImage');
//         dispatch(getPDFImageSuccess(pdfImage));
//     }
// }

// export const getPDFImageSuccess = (pdfImage) => {
//     return {
//         type: actionTypes.GET_PDF_IMAGE_SUCCESS,
//         pdfImage: pdfImage
//     }
// }

// export const getPDFFileSuccess = (pdfFile) => {
//     return {
//         type: actionTypes.GET_PDF_FILE_SUCCESS,
//         pdfFile: pdfFile
//     }
// }

// export const savePDF = (pdf) => {
//     return (dispatch) => {
//         const pdfData = JSON.stringify(pdf);

//         localStorage.setItem('pdfImage', pdfData);

//         dispatch(savePDFSuccess(pdfData));
//         // axios.put('/pdf.json', pdfData)
//         // .then(response => {
            
//         // })
//         // .catch(error => {
//         //     dispatch(savePDFFailed(error));
//         // })
//     }
// }

// export const savePDFFile = (pdfFile) => {
//     return (dispatch) => {
        
//         const pdfData = JSON.stringify(pdfFile);

//         //localStorage.setItem('pdfFile', pdfData);
//         //dispatch(savePDFSuccess(pdfData));
//         axios.put('/pdf.json', pdfData)
//         .then(response => {
//             dispatch(savePDFSuccess(response.data));
//         })
//         .catch(error => {
//             dispatch(savePDFFailed(error));
//         })
//     }
// }

// export const savePDFSuccess = (pdfFile) => {
//     return {
//         type: actionTypes.SAVE_PDF_SUCCESS,
//         pdfFile,
//         pdfImage: pdfFile
//     };
// };

// export const savePDFFailed = (error) => {
//     return {
//         type: actionTypes.SAVE_PDF_FAILED,
//         error: error
//     };
// };