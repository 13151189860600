import * as actionTypes from './actionTypes';
import axios from '../../axios-floors';
// import { v4 as uuidv4 } from 'uuid';

export const setTeams = (teams) => {
    return {
        type: actionTypes.SET_TEAMS,
        teamData: teams
    };
};

export const saveTempPoints = (points) => {
    return {
        type: actionTypes.SAVE_TEMP_POINTS,
        points
    }
}

export const saveScale = (scale) => {
    return (dispatch) => {
        axios.put('/scale.json', scale)
            .then(response => {
                dispatch(saveScaleSuccess(response.data));
            })
            .catch(error => {
                dispatch(saveScaleFailed(error));
            });
    };
};

export const saveScaleSuccess = (scale) => {
    return {
        type: actionTypes.SAVE_SCALE_SUCCESS,
        scale
    }
}

export const saveScaleFailed = (error) => {
    return {
        type: actionTypes.SAVE_SCALE_FAILED,
        error
    }
}

export const getScale = () => {
    return (dispatch) => {
        axios.get('/scale.json')
            .then(response => {
                dispatch(getScaleSuccess(response.data));
            })
            .catch(error => {
                dispatch(getScaleFailed(error));
            });
    };
};

export const getScaleSuccess = (scale) => {
    return {
        type: actionTypes.GET_SCALE_SUCCESS,
        scale
    }
}

export const getScaleFailed = (error) => {
    return {
        type: actionTypes.GET_SCALE_FAILED,
        error
    }
}

export const saveDistance = (distance) => {
    return {
        type: actionTypes.SAVE_DISTANCE,
        distance: distance
    }
}

export const addPoints = (points) => {
    return {
        type: actionTypes.POINTS,
        points
    }
}