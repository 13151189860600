import React, { Component } from 'react';
import { connect } from 'react-redux';

// import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';

import Results from '../../components/Results/Results';
import Aux from '../../hoc/Aux/Aux';
import Sider from '../../components/Layout/Sider/Sider';
// import Spinner from '../../components/UI/Spinner/Spinner';

import * as floorPlannerBuilderActions from '../../store/actions/floorPlannerBuilder';
// import * as uploadFileActions from '../../store/actions/uploadBuilder';
import * as scaleBuilderActions from '../../store/actions/scaleBuilder';

class ResultsBuilder extends Component {
    componentDidMount () {
        this.props.onFetchTeams();
        //this.props.onFetchPDF();
        this.props.updatePdfImage();
        this.props.onFetchScale();
    }
    
    render() {
        let results = <Results 
            teams={this.props.teams}
            spaceNorms={this.props.spaceNorms}
            scale={this.props.scale}
            pdfImage={this.props.pdfImage} />;

        return (
            <Aux>
                <Sider>{results}</Sider>
            </Aux>
            );
    }
}

const mapStateToProps = (state) => {
    return {
        teams: state.floorBuilder.teams,
        spaceNorms: state.spaceNormBuilder.spaceNorms,
        pdfImage: state.uploadBuilder.pdfImage,
        scale: state.floorBuilder.scale,
        loading: state.floorBuilder.loading,
        error: state.floorBuilder.error
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onFetchTeams: () => dispatch(floorPlannerBuilderActions.fetchTeams()),
        // onFetchPDF: () => dispatch(uploadFileActions.fetchPDF()),
        onFetchScale: () => dispatch(scaleBuilderActions.getScale()),
        updatePdfImage: () => dispatch({type: 'UPDATE_PDF_IMAGE'})
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResultsBuilder);
