import * as actionTypes from './actionTypes';
import axios from '../../axios-floors';

export const fetchSpaceNorms = () => {
    return dispatch => {
        axios.get('/spaceNorms.json')
        .then(response => {
            dispatch(setSpaceNorms(response.data));
        })
        .catch(error => {
            dispatch(fetchSpaceNormsFailed());
        })
    }
}

export const setSpaceNorms = (spaceNorms) => {
    return {
        type: actionTypes.SET_SPACE_NORMS,
        spaceNorms: spaceNorms
    }
}

export const fetchSpaceNormsSuccess = (spaceNormData) => {
    return {
        type: actionTypes.FETCH_SPACE_NORMS_SUCCESS,
        spaceNormData: spaceNormData
    }
}

export const fetchSpaceNormsFailed = () => {
    return {
        type: actionTypes.FETCH_SPACE_NORMS_FAILED
    }
}

// export const updateSurfaceArea = (surfaceArea) => {
//     //const payload = surfaceArea == "" ? 0 : parseFloat(surfaceArea);

//     return dispatch => {
//         axios.put('/spaceNorms/surfaceArea.json', parseFloat(surfaceArea))
//         .then(response => {
//             dispatch(updateSurfaceAreaSuccess(response.data));
//         })
//         .catch( error => {
//             dispatch(updateSurfaceAreaFailed(error));
//         });
//     };
// };
export const updateSurfaceArea = (surfaceArea) => {
    surfaceArea = surfaceArea.replace(/,/g, '.');
    return {
        type: actionTypes.UPDATE_SURFACE_AREA,
        surfaceArea
    }
};

// export const updateSurfaceAreaSuccess = (surfaceArea) => {
//     return {
//         type: actionTypes.UPDATE_SURFACE_AREA_SUCCESS,
//         surfaceArea: surfaceArea
//     }
// }

// export const updateSurfaceAreaFailed = (error) => {
//     return {
//         type: actionTypes.UPDATE_SURFACE_AREA_FAILED,
//         error: error
//     }
// }

// export const updateFlexFactor = (flexFactor) => {
//     return dispatch => {
//         axios.put('/spaceNorms/flexFactor.json', flexFactor)
//         .then(response => {
//             dispatch(updateFlexFactorSuccess(response.data));
//         })
//         .catch(error => {
//             dispatch(updateFlexFactorFailed(error));
//         });
//     };
// };

export const updateFlexFactor = (flexFactor) => {
    flexFactor = flexFactor.replace(/,/g, '.');
    return {
        type: actionTypes.UPDATE_FLEX_FACTOR,
        flexFactor
    }
}

// export const updateFlexFactorSuccess = (flexFactor) => {
//     return {
//         type: actionTypes.UPDATE_FLEX_FACTOR_SUCCESS,
//         flexFactor: flexFactor
//     }
// }

// export const updateFlexFactorFailed = (error) => {
//     return {
//         type: actionTypes.UPDATE_FLEX_FACTOR_FAILED,
//         error: error
//     }
// }