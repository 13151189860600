import * as actionTypes from '../actions/actionTypes';

const initialState = {
    points: [],
    scale: null,
    referenceSize: 1,
    tempPoints: []
}

const scaleBuilder = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.POINTS:
            return {
                ...state,
                points: action.points
            }
        case actionTypes.SAVE_SCALE_SUCCESS:
            return {
                ...state,
                scale: action.scale
            }
        case actionTypes.SAVE_SCALE_FAILED:
            return {
                ...state
            }
        case actionTypes.GET_SCALE_SUCCESS:
            return {
                ...state,
                scale: action.scale
            }
        case actionTypes.SAVE_REFERENCE_SIZE_SUCCESS:
            return {
                ...state,
                referenceSize: action.referenceSize
            }
        case actionTypes.GET_REFERENCE_SIZE:
            return {
                ...state,
                referenceSize: action.referenceSize
            }
        case actionTypes.GET_SCALE_FAILED:
            return {
                ...state,
                error: true
            }
        case actionTypes.SAVE_TEMP_POINTS:
            return {
                ...state,
                tempPoints: action.points
            }
        default:
            break;
    }

    return state;
}

export default scaleBuilder;